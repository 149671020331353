import React, { createContext, useContext, useState } from 'react';

const ScrollContext = createContext();

export const useScroll = () => useContext(ScrollContext);

export const ScrollProvider = ({ children }) => {
	const [scrollPositions, setScrollPositions] = useState(() => {
		const storedPositions = sessionStorage.getItem('scrollPositions');
		return storedPositions ? JSON.parse(storedPositions) : {};
	});

	const saveScrollPosition = (location, position) => {
		if (location.split('/').length > 2) return;
		else if (location === '/') return;
		else {
			setScrollPositions((prev) => ({
				...prev,
				[location]: position,
			}));
			sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions));
		}
	};

	const getScrollPosition = (location) => { return scrollPositions[location] || 0 };

	return (
		<ScrollContext.Provider value={{ saveScrollPosition, getScrollPosition }}>
			{children}
		</ScrollContext.Provider>
	);
};
