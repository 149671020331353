import React, { createContext, useContext, useState } from 'react';


const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
	const [loading, setIsLoading] = useState(false);

	const startLoading = () => setIsLoading(true);
	const stopLoading = () => setIsLoading(false);

	return (
		<LoadingContext.Provider value={{ loading, startLoading, stopLoading }}>
			{children}
		</LoadingContext.Provider>
	);
};

export const useLoading = () => {
	return useContext(LoadingContext);
};
