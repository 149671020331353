import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import ContactCard from '../components/ContactCard';
import PageBanner from '../components/PageBanner';


const News = ({ articles }) => {
	const [scrollDown, setScrollDown] = useState(0);
	useEffect(() => {
		const handleScroll = () => {
			setScrollDown((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<motion.div
			className='grid grid-cols-1 gap-8'
		// initial={{ opacity: 0 }}
		// animate={{ opacity: 1 }}
		// exit={{ opacity: 0 }}
		// transition={{ duration: 0.3 }}
		>
			<PageBanner image={`${process.env.PUBLIC_URL}/assets/system/sys_17.webp`} scrollDown={scrollDown} />

			<motion.div
				className='grid grid-cols-1 gap-4 md:gap-8 w-full mx-auto px-2 md:px-4 z-10'
			// initial={{ opacity: 0, y: 20 }}
			// animate={{ opacity: 1, y: 0 }}
			>
				<motion.div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-1'>
					{articles.map((article, index) => (
						<ContactCard
							key={index}
							link={`/notice/${article.id}`}
							target={null}
							thumbnail={article.image}
							title={article.title}
							subtitle={article.subtitle}
							internal={true}
						/>
					))}
				</motion.div>
			</motion.div>
		</motion.div>
	);
};

export default News;
