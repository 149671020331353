import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


const PeopleContext = createContext();

export const PeopleProvider = ({ children }) => {
	const [people, setPeople] = useState([]);
	const fetchPeople = async () => {
		try {
			const response = await axios.get(`https://and-undox.com/api/people`);
			// console.log('People:', response.data);
			setPeople(response.data);
		} catch (error) {
			console.error('Error fetching people:', error);
		}
	}

	return (
		<PeopleContext.Provider value={{ people, setPeople, fetchPeople }}>
			{children}
		</PeopleContext.Provider>
	);
};

export const usePeople = () => {
	return useContext(PeopleContext);
};