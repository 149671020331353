import React from "react";


const PageHeader = ({ title }) => {
	const handleClick = () => {
		let element = document.getElementById('head_' + title);
		if (element) {
			let position = element.getBoundingClientRect().top;
			let parent = element.parentElement;
			let parentPosition = parent.getBoundingClientRect().top;
			if (parentPosition !== 48) {
				window.scrollTo({ top: parentPosition + window.scrollY - 48, behavior: 'smooth' });
			}
		}
	};

	return (
		<h2
			id={`head_${title}`}
			onClick={handleClick}
			className={`font-extrabold text-4xl w-full mx-auto z-20 text-balance sticky top-12 bottom-12 mb-4`}
		>
			{title}
		</h2>
	);
}

export default PageHeader;
