import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [authToken, setAuthToken] = useState(null);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const token = localStorage.getItem('authToken');
		const user = localStorage.getItem('user');

		if (token) {
			setAuthToken(token);
			setUser(user);
		}
	}, []);

	const login = async (username, password) => {
		try {
			const response = await axios.post('https://and-undox.com/api/login', { username, password });
			const token = response.data.token;
			const user = response.data.user;

			console.log(response.data);

			setAuthToken(token);
			setUser(user);

			localStorage.setItem('authToken', token);
			localStorage.setItem('user', user);
		} catch (error) {
			console.error('Failed to login', error);
		} finally { }
	};

	const logout = () => {
		localStorage.removeItem('authToken');
		localStorage.removeItem('user');

		setAuthToken(null);
		setUser(null);
	};

	return (
		<AuthContext.Provider value={{ authToken, user, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);