import axios from 'axios';
import React, { createContext, useContext, useState } from 'react';


const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
	const [projects, setProjects] = useState([]);
	const fetchProjects = async () => {
		try {
			const response = await axios.get(`https://and-undox.com/api/projects`);
			// console.log('Projects:', response.data);
			setProjects(response.data);
		} catch (error) {
			console.error('Error fetching projects:', error);
		}
	}

	return (
		<ProjectsContext.Provider value={{ projects, setProjects, fetchProjects }}>
			{children}
		</ProjectsContext.Provider>
	);
};

export const useProjects = () => {
	return useContext(ProjectsContext);
};
