import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';
import { Link } from 'react-router-dom';

import MediaLoader from '../components/MediaLoader';
import PageBanner from '../components/PageBanner';
import { useProjects } from '../contexts/ProjectsContext';


const breakpointColumnsObj = {
	default: 6,
	1536: 5,
	1024: 4,
	768: 2
};

const Projects = () => {
	const { projects, setProjects, fetchProjects } = useProjects();

	const [selectedTags, setSelectedTags] = useState(JSON.parse(sessionStorage.getItem('selectedTags')) || []);
	const [scrollDown, setScrollDown] = useState(0);
	const [tags, setTags] = useState([]);

	const [currentIdx, setCurrentIdx] = useState(0);

	const handleTagClick = (tag) => {
		if (selectedTags.includes(tag)) {
			setSelectedTags(selectedTags.filter((t) => t !== tag));
			sessionStorage.setItem('selectedTags', JSON.stringify(selectedTags.filter((t) => t !== tag)));
		} else {
			setSelectedTags([...selectedTags, tag]);
			sessionStorage.setItem('selectedTags', JSON.stringify([...selectedTags, tag]));
		}
		console.log(sessionStorage.getItem('selectedTags'));
	};

	useEffect(() => {
		const handleScroll = () => {
			setScrollDown((window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 100);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	useEffect(() => {
		const tags = projects.reduce((acc, project) => {
			project.tags.forEach((tag) => {
				if (!acc.includes(tag)) {
					acc.push(tag);
				}
			});
			return acc;
		}, []);

		setTags(tags.sort());
		setSelectedTags(JSON.parse(sessionStorage.getItem('selectedTags')) || []);
	}, [projects]);

	useEffect(() => {
		if (currentIdx < projects.length) {
			const timer = setTimeout(() => {
				setCurrentIdx(currentIdx + 2);
			}, 50);

			return () => clearTimeout(timer);
		}
	}, [currentIdx, projects]);

	return (
		<motion.div
			className='grid grid-cols-1 gap-8 relative'
		// initial={{ opacity: 0 }}
		// animate={{ opacity: 1 }}
		// exit={{ opacity: 0 }}
		// transition={{ duration: 0.3 }}
		>
			<PageBanner image={`${process.env.PUBLIC_URL}/assets/system/sys_13.webp`} scrollDown={scrollDown} />

			<motion.div
				className='grid grid-cols-1 gap-4 md:gap-8 w-full mx-auto px-2 md:px-4 z-10'
			// initial={{ opacity: 0, y: 20 }}
			// animate={{ opacity: 1, y: 0 }}
			>
				<div className='grid grid-cols-1 gap-2'>
					<div className='w-full flex flex-row gap-[2px] md:gap-1 flex-wrap text-xs'>
						{tags
							.filter((tag) => !tag.includes('System'))
							.map((tag) => (
								<button
									key={tag}
									className={`tag	${selectedTags.includes(tag) ? 'tag-selected' : ''}`}
									onClick={() => handleTagClick(tag)}
								>
									#{tag}
								</button>
							))}
						{tags.length === 0 && (
							<span className='tag animate-pulse'>Loading Tags</span>
						)}
					</div>
				</div>

				<Masonry
					breakpointCols={breakpointColumnsObj}
					className="my-masonry-grid flex gap-[2px] md:gap-1 min-h-[4000px] "
					columnClassName="my-masonry-grid_column flex flex-col gap-[2px] md:gap-1"
				>
					{projects
						.sort((a, b) => new Date(b.finished_date) - new Date(a.finished_date))
						.filter((project) => !project.tags.includes('System'))
						.filter((project) => selectedTags.length === 0 || project.tags.some((tag) => selectedTags.includes(tag)))
						.map((project, index) => (
							<motion.div
								key={project._id}
								className={`relative group overflow-hidden transition-all ease-in-out`}
							>
								<Link
									to={`/archive/${project._id}`}
									className='w-full h-full transition-all ease-in-out'
								>
									{project.images.length > 0 && (
										<MediaLoader
											src={`https://and-undox.com/api/uploads/images/${project.images[0]}`}
											type='image'
											alt={project.title}
											thumb={true}
											wait={index > currentIdx}
											className={`w-full group-hover:brightness-50 transition-all ease-in-out`}
										/>
									)}
									<div
										className='
											text-balance
											group-hover:opacity-100 opacity-0 transition-all ease-in-out dark:bg-black dark:bg-opacity-30 bg-neutral-50 bg-opacity-30
											absolute top-0 grid font-extrabold justify-around w-full h-full px-4 content-center text-center'
									>
										{project.title}
									</div>
								</Link>
							</motion.div>
						))}
				</Masonry>
			</motion.div>
		</motion.div>
	);
};

export default Projects;
