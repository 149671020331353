import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';


const logo =
	<svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 214.28 80" fill="currentColor">
		<path d="M214.28,0s-52.33,34.49-107.14,34.49S0,0,0,0c0,0,48.28,49.97,3.19,79.26,0,0,47.51-17.52,103.96-17.52s103.96,17.52,103.96,17.52C166.02,49.97,214.3,0,214.3,0h-.02Z" />
	</svg>

const MediaLoader = ({ src, type, alt, className, imgClassName, vidClassName, thumb, wait }) => {
	const [loaded, setLoaded] = useState(false);

	if (!src || !type) {
		return <p key="error">Source or type is missing</p>;
	}

	return (
		<motion.div
			key={src}
			className={`w-fit h-fit min-h-20 relative ${className}`}
		>
			<AnimatePresence mode='sync'>
				{(type === 'image' && (!wait || loaded)) && (
					<motion.img
						key={src}
						src={thumb ? `${src.split('.').slice(0, -1).join('.')}@0.5.${src.split('.').pop()}` : src}						alt={alt || 'media'}
						onLoad={() => setLoaded(true)}
						className={imgClassName}
					/>
				)}
				{type === 'video' && (
					<motion.video
						key={src}
						controls
						src={src}
						playsInline
						autoPlay
						muted
						onLoadedData={() => setLoaded(true)}
						className={vidClassName}
					/>
				)}
				{!loaded && (
					<motion.div
						key="loader"
						className="
							absolute inset-0 flex p-4 bg-black
							justify-center items-center text-neutral-50 *:h-16
						"
						exit={{ opacity: 0 }}
					>
						{logo}
					</motion.div>
				)}
			</AnimatePresence>
		</motion.div>
	);
};

export default MediaLoader;